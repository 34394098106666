<template>
  <router-view/>
</template>

<style lang="scss">
* {
  margin:0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
}
</style>
